import PostMessageIO from '@atlassian/trello-post-message-io';
import Promise from 'bluebird';

export default (handlers, options) => {
  const opts = options || {};

  const io = new (opts.io || PostMessageIO)({
    Promise,
    local: window,
    remote: window.parent,
    targetOrigin: opts.targetOrigin || 'https://trello.com',
    secret: opts.secret,
    strict: true,
    handlers,
    hostHandlers: opts.hostHandlers,
    helpfulStacks: typeof opts.helpfulStacks === 'boolean' ? opts.helpfulStacks : true,
    Sentry: opts.Sentry,
  });

  return io;
};
