import makeError from 'make-error';

const reservedBaseName = 'Error';

export default (namespace, names) => {
  const baseClass = makeError([namespace, reservedBaseName].join('::'));

  names.forEach((name) => {
    baseClass[name] = makeError([namespace, name].join('::'), baseClass);
  });

  return baseClass;
};
