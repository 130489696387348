/*

The intention of simple-crypto is to expose AES-CBC encryption & decryption methods in an
easy to use manner, in a way that works across as many browsers as reasonable.

Current Browser Support:

Chrome: 41+
Edge: 14+
Firefox: 36+
IE: 11+
Safari: 7.1+ (Maverics or newer)
iPhone: iOS 8+
Android: 4.4+

Why use AES-CBC which has no validation/integrity check built into the algorithm instead of
something like AES-GCM which does? AES-GCM is sadly not supported on Safari, and therefore, AES-CBC
was the greatest common denominator, being the most widely supported across browsers.

Instead we use a SHA256 digest to build in our own integrity check on top of AES-CBC.

*/

import { TextEncoder, TextDecoder } from 'text-encoding-utf-8';
import Promise from 'bluebird';
import { bytesToHexString, hexStringToUint8Array } from './convert';

const isFunc = (test) => typeof test === 'function';

const Encoder = isFunc(window.TextEncoder) ? window.TextEncoder : TextEncoder;
const Decoder = isFunc(window.TextDecoder) ? window.TextDecoder : TextDecoder;

// Constant Encryption Settings
const mode = 'AES-CBC';
const aesOpts = { name: mode, length: 256 };
const exportable = true;
const keyUses = ['encrypt', 'decrypt'];

// Select the right subtleCrypto function based on the browser
const useWindowCrypto = typeof window.crypto === 'object'; // Chrome, Firefox, Safari, Edge
const useMsCrypto = !useWindowCrypto && typeof window.msCrypto === 'object'; // IE11

let subtle;
if (useWindowCrypto) {
  // Safari still prefixes the subtle methods, so fix that if necessary
  if (window.crypto.subtle == null && window.crypto.webkitSubtle != null) {
    subtle = window.crypto.webkitSubtle;
  } else {
    // eslint-disable-next-line prefer-destructuring
    subtle = window.crypto.subtle;
  }
} else if (useMsCrypto) {
  // eslint-disable-next-line prefer-destructuring
  subtle = window.msCrypto.subtle;
}

const SHA256Digest = (text) => {
  const textBuff = new Encoder().encode(text);
  return new Promise((resolve, reject) => {
    if (useWindowCrypto) {
      // The digest will be an ArrayBuffer, we want to convert that to a hex string
      subtle
        .digest({ name: 'SHA-256' }, textBuff)
        .then((digest) => resolve(bytesToHexString(digest)));
    } else if (useMsCrypto) {
      const digestOp = subtle.digest({ name: 'SHA-256' }, textBuff);
      digestOp.oncomplete = (e) => {
        resolve(bytesToHexString(e.target.result));
      };
      digestOp.onerror = (err) => {
        reject(new Error(`Error digesting text: ${err.type}`));
      };
    } else {
      reject(new Error('Browser not supported.'));
    }
  });
};

export default {
  // synchronously generate an initialization vector for later use during encryption
  generateInitVector: () => {
    if (useWindowCrypto && typeof window.crypto.getRandomValues === 'function') {
      return window.crypto.getRandomValues(new Uint8Array(16));
    }
    if (useMsCrypto && typeof window.msCrypto.getRandomValues === 'function') {
      // IE11 fallback
      return window.msCrypto.getRandomValues(new Uint8Array(16));
    }

    throw new Error('Browser not supported.');
  },
  // returns a promise that resolves to an AES-CBC 256 bit encryption key
  generateAESCBCKey: () =>
    new Promise((resolve, reject) => {
      if (useWindowCrypto) {
        resolve(subtle.generateKey(aesOpts, exportable, keyUses));
      } else if (useMsCrypto) {
        // IE11 uses msCrypto, which doesn't support Promises
        const keyOp = subtle.generateKey(aesOpts, exportable, keyUses);
        keyOp.oncomplete = (e) => {
          resolve(e.target.result);
        };
        keyOp.onerror = (err) => {
          reject(new Error(`Error generating key: ${err.type}`));
        };
      } else {
        reject(new Error('Browser not supported.'));
      }
    }),
  // given a hex string representing a raw exported encryption key
  // return promise that resolves to the actual key
  importAESCBCKeyFromRaw: (rawHexString) => {
    const hexAsBuff = hexStringToUint8Array(rawHexString);
    return new Promise((resolve, reject) => {
      if (useWindowCrypto) {
        resolve(subtle.importKey('raw', hexAsBuff, mode, exportable, keyUses));
      } else if (useMsCrypto) {
        // IE11 uses msCrypto, which doesn't support Promises
        const importOp = subtle.importKey('raw', hexAsBuff, mode, exportable, keyUses);
        importOp.oncomplete = (e) => {
          resolve(e.target.result);
        };
        importOp.onerror = (err) => {
          reject(new Error(`Error importing key: ${err.type}`));
        };
      } else {
        reject(new Error('Browser not supported.'));
      }
    });
  },
  // returns a hex string representation of the encryption key
  exportAESCBCKeyToRaw: (key) =>
    new Promise((resolve, reject) => {
      if (useWindowCrypto) {
        subtle.exportKey('raw', key).then((raw) => resolve(bytesToHexString(raw)));
      } else if (useMsCrypto) {
        const exportOp = subtle.exportKey('raw', key);
        exportOp.oncomplete = (e) => {
          resolve(bytesToHexString(e.target.result));
        };
        exportOp.onerror = (err) => {
          reject(new Error(`Error exporting key: ${err.type}`));
        };
      } else {
        reject(new Error('Browser not supported.'));
      }
    }),
  // returns a Promise that resolves to a hex string representation of the encrypted secret.
  // The secret should be a valid UTF-8 string
  encryptSecret: (initVector, key, secret) =>
    SHA256Digest(secret).then((digest) => {
      // prepend the plaintext with its SHA256 digest
      const secretBuff = new Encoder().encode(digest + secret);
      if (useWindowCrypto) {
        return subtle
          .encrypt({ name: mode, iv: initVector }, key, secretBuff)
          .then((encrypted) => bytesToHexString(encrypted));
      }
      if (useMsCrypto) {
        return new Promise((resolve, reject) => {
          const encryptOp = subtle.encrypt({ name: mode, iv: initVector }, key, secretBuff);
          encryptOp.oncomplete = (e) => {
            resolve(bytesToHexString(e.target.result));
          };
          encryptOp.onerror = (err) => {
            reject(new Error(`Error encrypting secret: ${err.type}`));
          };
        });
      }

      return Promise.reject(new Error('Browser not supported.'));
    }),
  // takes the initialization vector and key that were used to encrypt the secret originally, as
  // well as the encryptedSecret as a hexString, and returns a Promise that resolves to the
  // decrypted secret as a UTF-8 string
  decryptSecret: (initVector, key, encryptedSecret) => {
    const encryptedAsBuff = hexStringToUint8Array(encryptedSecret);
    return new Promise((resolve, reject) => {
      if (useWindowCrypto) {
        subtle
          .decrypt({ name: mode, iv: initVector }, key, encryptedAsBuff)
          .then((decrypted) => {
            resolve(new Decoder().decode(decrypted));
          })
          .catch((err) => {
            reject(new Error(`Decryption failed. Message: ${err.message}`));
          });
      } else if (useMsCrypto) {
        const decryptOp = subtle.decrypt({ name: mode, iv: initVector }, key, encryptedAsBuff);
        decryptOp.oncomplete = (e) => {
          resolve(new Decoder().decode(e.target.result));
        };
        decryptOp.onerror = (err) => {
          reject(new Error(`Decryption failed. Message: ${err.type}`));
        };
      } else {
        reject(new Error('Browser not supported.'));
      }
    }).then((decrypted) => {
      // verify the integrity of the decrypted plaintext
      const mac = decrypted.substring(0, 64);
      const plaintext = decrypted.substring(64);
      if (!/^[a-f0-9]{64}$/.test(mac)) {
        throw new Error('Decryption failed. Unable to validate integrity.');
      }
      return SHA256Digest(plaintext).then((digest) => {
        if (digest === mac) {
          return plaintext;
        }
        throw new Error('Decryption failed. Unable to validate integrity.');
      });
    });
  },
  // returns a Promise that resolves to a hex string representing the SHA256 digest of the provided
  // UTF-8 text
  sha256Digest: SHA256Digest,
};
