export function bytesToHexString(bytes) {
  if (!bytes) {
    return null;
  }
  // Check the incoming bytes to make sure there are no overflows that can't fit in
  // a Uint8Array (so anything over 255)
  // Why not use something like bytes.some(function (b) { b > 255 ... }) ?
  // Because for typed arrays that is not supported by IE or Safari under 10
  for (let i = 0; i < bytes.length; i += 1) {
    const b = bytes[i];
    if (typeof b !== 'number' || b % 1 !== 0 || b > 255 || b < 0) {
      throw new Error('Invalid bytes. Bytes must be 0 - 255');
    }
  }
  const buff = new Uint8Array(bytes);
  const hexBytes = [];
  for (let j = 0; j < buff.length; j += 1) {
    let byteString = buff[j].toString(16);
    if (byteString.length < 2) {
      byteString = `0${byteString}`;
    }
    hexBytes.push(byteString);
  }
  return hexBytes.join('');
}

export function hexStringToUint8Array(hexString) {
  if (!hexString) {
    throw new Error('Invalid hexString');
  }
  const hex = hexString.toLowerCase();
  if (!/^[0-9a-f]+$/.test(hex) || hex.length % 2 !== 0) {
    throw new Error('Invalid hexString');
  }
  const arrayBuffer = new Uint8Array(hex.length / 2);
  for (let i = 0; i < hex.length; i += 2) {
    const byteValue = parseInt(hex.substr(i, 2), 16);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(byteValue)) {
      throw new Error('Invalid hexString');
    }
    arrayBuffer[i / 2] = byteValue;
  }
  return arrayBuffer;
}
