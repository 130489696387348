import has from 'lodash.has';
import warn from './warn';

let hashData = null;

export default (key, defaultValue) => {
  if (!hashData) {
    try {
      hashData = JSON.parse(decodeURIComponent(window.location.hash.replace(/^#/, '')));
    } catch (error) {
      warn('Power-Up unable to parse url hash, perhaps you needed a t.signUrl(url)?', error);
      hashData = {};
    }
  }
  if (has(hashData, key)) {
    return hashData[key];
  }
  return defaultValue;
};
