import AuthMethods from './authorize';

export { default as restApiError } from './error';

export default class RestApi {
  constructor({
    t,
    appKey,
    appName,
    appAuthor,
    apiOrigin = 'https://api.trello.com',
    authOrigin = 'https://trello.com',
    localStorage = window.localStorage,
    tokenStorageKey = 'trello_token',
  }) {
    this.appKey = appKey;
    this.appName = appName;
    this.appAuthor = appAuthor;
    this.apiOrigin = apiOrigin;
    this.authOrigin = authOrigin;
    this.t = t;
    this.localStorage = localStorage;
    this.tokenStorageKey = tokenStorageKey;
  }

  init() {
    this.checkAndStoreToken();
  }

  apiBase() {
    return `${this.apiOrigin}/1`;
  }

  authBase() {
    return `${this.authOrigin}/1`;
  }
}

Object.keys(AuthMethods).forEach((method) => {
  RestApi.prototype[method] = AuthMethods[method];
});
