import Promise from 'bluebird';
import i18n from './i18n';
import i18nError from './i18n-error';

export default (locale, options) => {
  const opts = options || {};
  let errmsg = '';

  if (!locale) {
    errmsg = 'Unable to load a localizer without a locale';
    return Promise.reject(new i18nError.LocaleNotSpecified(errmsg));
  }

  if (window.localizer) {
    return Promise.resolve();
  }

  if (opts.localizer) {
    window.localizer = opts.localizer;
  } else if (opts.loadLocalizer) {
    if (typeof opts.loadLocalizer === 'function') {
      return Promise.resolve(opts.loadLocalizer(locale)).then((localizer) => {
        window.localizer = localizer;
        return Promise.resolve();
      });
    }
    errmsg =
      'Specified loadLocalizer must be a function that returns a localizer or a Promise resolving to a localizer';
    return Promise.reject(new i18nError.LoadLocalizerNotAFunction(errmsg));
  } else if (opts.localization) {
    const { defaultLocale, supportedLocales, resourceUrl } = opts.localization;
    if (!defaultLocale) {
      return Promise.reject(new i18nError.MissingDefaultLocale('Missing defaultLocale'));
    }
    if (!supportedLocales) {
      return Promise.reject(new i18nError.MissingSupportedLocales('Missing supportedLocales'));
    }
    if (!resourceUrl) {
      return Promise.reject(new i18nError.MissingResourceUrl('Missing resourceUrl'));
    }
    return i18n
      .loadLocalizer(locale, defaultLocale, supportedLocales, resourceUrl)
      .then((localizer) => {
        window.localizer = localizer;
        return Promise.resolve();
      });
  }
  return Promise.resolve();
};
