import makeErrorEnum from './util/make-error-enum';

export default makeErrorEnum('i18n', [
  'ArgNotFound',
  'InvalidResourceUrl',
  'KeyNotFound',
  'LoadLocalizerNotAFunction',
  'LocaleNotFound',
  'LocaleNotSpecified',
  'LocalizerNotFound',
  'MissingDefaultLocale',
  'MissingResourceUrl',
  'MissingSupportedLocales',
  'UnableToParseArgs',
  'UnableToParseAttrs',
  'Unknown',
  'UnsupportedKeyType',
]);
