import has from 'lodash.has';
import Promise from 'bluebird';
import warn from './util/warn';

const temporary = {};
const retained = {};
let index = 0;

setInterval(() => {
  const refs = Object.keys(temporary);
  const now = Date.now();

  refs.forEach((ref) => {
    const entry = temporary[ref];
    if (entry.expires < now) {
      delete temporary[ref];
    }
  });
}, 30000);

export default {
  callback: (t, options, serializeResult) => {
    const ref = options.callback;
    const { action } = options;
    const args = options.options;

    return Promise.try(() => {
      switch (action) {
        case 'run':
          if (has(retained, ref)) {
            return retained[ref].call(null, t, args);
          }
          // fallback and check the temporary cache in case it hadn't been retained
          if (has(temporary, ref)) {
            retained[ref] = temporary[ref].fx;
            delete temporary[ref];
            return retained[ref].call(null, t, args);
          }
          warn('Attempted to run callback that does not exist or was not retained');
          throw t.NotHandled('callback does not exist or was not retained');
        case 'retain':
          if (has(temporary, ref)) {
            retained[ref] = temporary[ref].fx;
            delete temporary[ref];
            return ref;
          }
          warn('Attempted to retain callback that does not exist');
          throw t.NotHandled('callback can no longer be retained');
        case 'release':
          if (has(retained, ref)) {
            delete retained[ref];
            return null;
          }
          warn('Attempted to release callback that is not retained');
          return null;
        default:
          warn('Attempted an unknown callback action');
          throw t.NotHandled('unknown callback action');
      }
    }).then(serializeResult);
  },
  serialize: (fx) => {
    const ref = `cb${(index += 1)}`;
    temporary[ref] = {
      fx,
      expires: Date.now() + 120000, // keep around for 2 minutes
    };

    return {
      _callback: ref,
    };
  },
  reset: () => {
    index = 0;
    Object.keys(temporary).forEach((ref) => {
      delete temporary[ref];
    });
    Object.keys(retained).forEach((ref) => {
      delete retained[ref];
    });
  },
};
