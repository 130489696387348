import isFinite from 'lodash.isfinite';
import warn from './warn';

const colors = {
  blue: {
    50: '#E4F0F6',
    100: '#BCD9EA',
    200: '#8BBDD9',
    300: '#5BA4CF',
    400: '#298FCA',
    500: '#0079BF',
    600: '#026AA7',
    700: '#055A8C',
    800: '#094C72',
    900: '#0C3953',
  },
  green: {
    50: '#EEF6EC',
    100: '#D6ECD2',
    200: '#B7DDB0',
    300: '#99D18F',
    400: '#7BC86C',
    500: '#61BD4F',
    600: '#5AAC44',
    700: '#519839',
    800: '#49852E',
    900: '#3F6F21',
  },
  orange: {
    50: '#FDF5EC',
    100: '#FCE8D2',
    200: '#FAD8B0',
    300: '#FDC788',
    400: '#FFB968',
    500: '#FFAB4A',
    600: '#E99E40',
    700: '#D29034',
    800: '#BB8129',
    900: '#A0711C',
  },
  red: {
    50: '#FBEDEB',
    100: '#F5D3CE',
    200: '#EFB3AB',
    300: '#EC9488',
    400: '#EF7564',
    500: '#EB5A46',
    600: '#CF513D',
    700: '#B04632',
    800: '#933B27',
    900: '#6E2F1A',
  },
  yellow: {
    50: '#FDFAE5',
    100: '#FAF3C0',
    200: '#F5EA92',
    300: '#F3E260',
    400: '#F5DD29',
    500: '#F2D600',
    600: '#E6C60D',
    700: '#D9B51C',
    800: '#CCA42B',
    900: '#BD903C',
  },
  purple: {
    50: '#F7F0FA',
    100: '#EDDBF4',
    200: '#DFC0EB',
    300: '#D5A6E6',
    400: '#CD8DE5',
    500: '#C377E0',
    600: '#A86CC1',
    700: '#89609E',
    800: '#6C547B',
    900: '#484553',
  },
  pink: {
    50: '#FEF2F9',
    100: '#FCDEF0',
    200: '#FAC6E5',
    300: '#FFB0E1',
    400: '#FF95D6',
    500: '#FF80CE',
    600: '#E76EB1',
    700: '#CD5A91',
    800: '#B44772',
    900: '#96304C',
  },
  sky: {
    50: '#E4F7FA',
    100: '#BDECF3',
    200: '#8FDFEB',
    300: '#5DD3E5',
    400: '#29CCE5',
    500: '#00C2E0',
    600: '#00AECC',
    700: '#0098B7',
    800: '#0082A0',
    900: '#006988',
  },
  lime: {
    50: '#ECFBF3',
    100: '#D3F6E4',
    200: '#B3F1D0',
    300: '#90ECC1',
    400: '#6DECA9',
    500: '#51E898',
    600: '#4FD683',
    700: '#4DC26B',
    800: '#4CAF54',
    900: '#4A9839',
  },
  gray: {
    50: '#F8F9F9',
    100: '#EDEFF0',
    200: '#E2E4E6',
    300: '#D6DADC',
    400: '#CDD2D4',
    500: '#C4C9CC',
    600: '#B6BBBF',
    700: '#A5ACB0',
    800: '#959DA1',
    900: '#838C91',
  },
  black: {
    50: '#C1C7D0',
    100: '#7A869A',
    200: '#6B778C',
    300: '#5E6C84',
    400: '#505F79',
    500: '#42526E',
    600: '#344563',
    700: '#253858',
    800: '#172B4D',
    900: '#091E42',
  },
  'business-blue': {
    50: '#EDEFF4',
    100: '#D2D7E5',
    200: '#B2B9D0',
    300: '#838FB5',
    400: '#6170A1',
    500: '#42548E',
    600: '#3E4D80',
    700: '#3A476F',
    800: '#36405F',
    900: '#30364C',
  },
  shades: {
    0: '#FFFFFF',
    10: '#FAFCFC',
    20: '#F5F6F7',
    30: '#EBEEF0', // card back background
    40: '#DFE3E6',
    50: '#C2CCD1',
    60: '#B3BAC5',
    70: '#A6B3BA',
    80: '#97A7B0',
    90: '#899AA3',
    100: '#798D99', // icon color - default
    200: '#6B808C', // quiet text
    300: '#5E7785',
    400: '#516B7A', // icon color - dark
    500: '#425E6E',
    600: '#355263', // black label
    700: '#254659',
    800: '#17394D', // default text color
    900: '#092D42',
  },
};

colors.neutrals = colors.shades;

const getHexString = (name, weight) => {
  warn(
    'The colors utility has been deprecated and will be removed in future versions. Please refer to the Color Theme Compliance docs for an alternative, see: https://developer.atlassian.com/cloud/trello/power-ups/color-theme-compliance/using-atlassian-design-tokens/#how-do-i-get-the-value-of-a-token-in-javascript-'
  );

  if (!name || typeof name !== 'string') {
    throw new Error('Invalid color name');
  }
  const colorName = name.toLowerCase();
  if (!colors[colorName]) {
    throw new Error(`Unknown color name: ${colorName}`);
  }
  const colorWeight = isFinite(weight) ? weight : 500;
  if (!colors[colorName][colorWeight]) {
    throw new Error(`Unknown color weight: ${colorWeight}`);
  }
  return colors[colorName][colorWeight];
};

const namedColorStringToHex = (s) => {
  if (!s || typeof s !== 'string') {
    throw new Error('Invalid named color string');
  }
  if (/^#[a-fA-F0-9]{6}$/.test(s)) {
    return s;
  }

  // example strings: "blue", "business-blue", "green#50", "business-blue#200"
  const colorParts = /^([a-zA-Z-]+)(#[1-9]?0?0)?$/.exec(s);
  if (!colorParts) {
    throw new Error('Invalid accentColor provided');
  }
  const colorName = colorParts[1];
  let colorWeight = 500;
  if (colorParts[2]) {
    colorWeight = parseInt(colorParts[2].substring(1), 10);
  }
  return getHexString(colorName, colorWeight);
};

export { getHexString, namedColorStringToHex };
