// https://raw.githubusercontent.com/jonathantneal/closest/master/closest.js
// eslint-disable-next-line func-names
(function (ELEMENT) {
  // eslint-disable-next-line no-param-reassign
  ELEMENT.matches =
    ELEMENT.matches ||
    ELEMENT.mozMatchesSelector ||
    ELEMENT.msMatchesSelector ||
    ELEMENT.oMatchesSelector ||
    ELEMENT.webkitMatchesSelector;
  // eslint-disable-next-line no-param-reassign
  ELEMENT.closest =
    ELEMENT.closest ||
    function closest(selector) {
      let element = this;

      while (element) {
        if (element.matches(selector)) {
          break;
        }

        element = element.parentElement;
      }

      return element;
    };
})(window.Element.prototype);
