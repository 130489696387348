import get from 'lodash.get';
import Promise from 'bluebird';

import i18nError from './i18n-error';

const urlForLocale = (baseResourceUrl, locale) => {
  if (!baseResourceUrl.includes('{locale}')) {
    throw new i18nError.InvalidResourceUrl(
      'ResourceUrl must specify where to place locale with {locale}'
    );
  }
  return baseResourceUrl.replace('{locale}', locale);
};

const closestSupportedLocale = (requestedLocale, defaultLocale, supportedLocales) => {
  if (supportedLocales.includes(requestedLocale)) {
    return requestedLocale;
  }
  if (requestedLocale.includes('-')) {
    return closestSupportedLocale(requestedLocale.split('-')[0], defaultLocale, supportedLocales);
  }
  return defaultLocale;
};

export class Localizer {
  constructor(resourceDictionary) {
    this.resourceDictionary = resourceDictionary;
  }

  localize(key, args, opts = { dotNotation: false }) {
    const rawString = opts?.dotNotation
      ? get(this.resourceDictionary, key)
      : this.resourceDictionary[key];
    if (rawString != null) {
      if (args) {
        const holeRegex = opts?.dotNotation ? /\{(\w+?(?:\.\w+?)*\w+?)\}/gi : /\{(\w+?)\}/gi;
        let formattedString = '';
        let hole = holeRegex.exec(rawString);
        let index = 0;
        while (hole) {
          if (hole.index > index) {
            formattedString += rawString.substring(index, hole.index);
          }
          const replacement = opts?.dotNotation ? get(args, hole[1]) : args[hole[1]];
          if (replacement !== undefined) {
            formattedString += replacement;
            index = hole.index + hole[0].length;
          } else {
            throw new i18nError.ArgNotFound(`Arg: ${hole[1]} was not defined for key: ${key}`);
          }
          hole = holeRegex.exec(rawString);
        }
        formattedString += rawString.substring(index);
        return formattedString;
      }
      return rawString;
    }
    throw new i18nError.KeyNotFound(`No resource defined for key: ${key}`);
  }
}

let loadOnce;
let localizer;
let activeLocale = '';

const loadLocalizer = (requested, defaultLocale, supported, resourceUrl) =>
  Promise.try(() => {
    let targetLocale;
    if (!requested) {
      targetLocale = defaultLocale;
    } else {
      targetLocale = closestSupportedLocale(requested, defaultLocale, supported);
    }

    if (targetLocale === activeLocale && localizer) {
      return localizer;
    }

    if (!loadOnce) {
      loadOnce = new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();

        request.open('GET', urlForLocale(resourceUrl, targetLocale), true);
        request.onload = () => {
          try {
            if (request.status === 200) {
              const resources = JSON.parse(request.responseText);
              localizer = new Localizer(resources);
              activeLocale = targetLocale;
              return resolve(localizer);
            }
            if (request.status === 404) {
              return reject(new i18nError.LocaleNotFound(`${targetLocale} not found.`));
            }
            return reject(
              new i18nError.Unknown(
                `Unable to load locale: ${targetLocale}, status: ${request.status}`
              )
            );
          } catch (ex) {
            return reject(new i18nError.Unknown(ex.message));
          }
        };
        request.send();
      });
    }
    return loadOnce;
  });

export const reset = () => {
  loadOnce = undefined;
  localizer = undefined;
  activeLocale = '';
};

export default {
  loadLocalizer,
};
