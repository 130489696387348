import CallbackCache from './callback-cache';
import relativeUrl from './util/relative-url';

const process = (value, key) => {
  if (!value) {
    return value;
  }
  if ((key === 'url' || key === 'icon') && typeof value === 'string') {
    if (value.indexOf('./') === 0) {
      return relativeUrl(value.substr(2));
    }
  }
  const processed = {};
  switch (typeof value) {
    case 'object':
      if (Array.isArray(value)) {
        return value.map(process);
      }
      Object.keys(value).forEach((k) => {
        processed[k] = process(value[k], k);
      });
      return processed;
    case 'function':
      return CallbackCache.serialize(value);
    default:
      return value;
  }
};

export default process;
