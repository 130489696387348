import PostMessageIO from '@atlassian/trello-post-message-io';
import Promise from 'bluebird';

import { getHexString, namedColorStringToHex } from './util/colors';
import { bytesToHexString, hexStringToUint8Array } from './util/convert';
import simpleCrypto from './util/simple-crypto';
import initi18n from './initialize-i18n';
import { localizeKey, localizeKeys, localizeNode } from './util/localize';
import makeErrorEnum from './util/make-error-enum';
import relativeUrl from './util/relative-url';
import CallbackCache from './callback-cache';
import TrelloPlugin from './plugin';
import TrelloIFrame from './iframe';
import warn from './util/warn';
import { restApiError } from './rest-api';

import './compatibility/closest';

class TrelloPowerUp {
  constructor() {
    this.version = 'BUILD_VERSION';
    this.CallbackCache = CallbackCache;
    this.PostMessageIO = PostMessageIO;
    this.Promise = Promise;
    this.util = {
      colors: { getHexString, namedColorStringToHex },
      convert: { bytesToHexString, hexStringToUint8Array },
      crypto: simpleCrypto,
      initLocalizer: initi18n,
      i18n: { localizeKey, localizeKeys, localizeNode },
      makeErrorEnum,
      relativeUrl,
    };

    this.restApiError = restApiError;

    this.initialize = this.initialize.bind(this);
    this.iframe = this.iframe.bind(this);
  }

  initialize(handlers, options) {
    if (this.iframeConnector != null) {
      warn(
        'Cannot call TrelloPowerUp.initialize() from a secondary iframe where you have already called TrelloPowerUp.iframe(). TrelloPowerUp.initialize() should only be called from your index connector page, and should not include a call to TrelloPowerUp.iframe()'
      );
    }

    if (this.indexConnector != null) {
      warn(
        'Warning: calling TrelloPowerUp.initialize() more than once will have no effect. It is expected that you call it only once on your index connector.'
      );
      return this.indexConnector;
    }
    this.indexConnector = new TrelloPlugin(handlers, options);
    this.indexConnector.init();
    return this.indexConnector;
  }

  iframe(options) {
    if (this.indexConnector != null) {
      warn(
        'Cannot call TrelloPowerUp.iframe() from your index connector where you call TrelloPowerUp.initialize(). TrelloPowerUp.iframe() is only used for secondary iframes you may create or request from Trello during the Power-Up lifecycle.'
      );
    }

    if (this.iframeConnector != null) {
      return this.iframeConnector;
    }
    this.iframeConnector = new TrelloIFrame(options);
    this.iframeConnector.init();
    return this.iframeConnector;
  }
}

export default TrelloPowerUp;
