export default (url) => {
  if (/^https?:\/\//.test(url)) {
    return url;
  }

  // IE has no location.origin until IE11 ...
  const origin = window.location.origin || `${window.location.protocol}//${window.location.host}`;

  // strip the last bit from the current path
  const basePath = window.location.pathname.replace(/[^/]+$/, '');

  // protocol relative URLs
  if (/^\/\//.test(url)) {
    return [origin, url.substring(1)].join('');
  }

  // root relative URLs
  if (/^\//.test(url)) {
    return [origin, url].join('');
  }

  return [origin, basePath, url].join('');
};
