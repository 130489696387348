import i18nError from '../i18n-error';

const localizeKey = (key, data, opts = { dotNotation: false }) => {
  if (window.localizer && typeof window.localizer.localize === 'function') {
    return window.localizer.localize(key, data, opts);
  }

  throw new i18nError.LocalizerNotFound('No localizer available for localization.');
};

const localizeKeys = (keys, opts = { dotNotation: false }) => {
  if (!keys) {
    return [];
  }
  return keys.map((key) => {
    if (typeof key === 'string') {
      return localizeKey(key, {}, opts);
    }
    if (Array.isArray(key)) {
      return localizeKey(key[0], key[1], opts);
    }

    throw new i18nError.UnsupportedKeyType(
      `localizeKeys doesn't recognize the supplied key type: ${typeof key}`
    );
  });
};

const localizeNode = (node, opts = { dotNotation: false }) => {
  const localizableNodes = node.querySelectorAll('[data-i18n-id],[data-i18n-attrs]');
  for (let i = 0, len = localizableNodes.length; i < len; i += 1) {
    let replacementArgs = {};
    const element = localizableNodes[i];
    if (element.dataset.i18nArgs) {
      try {
        replacementArgs = JSON.parse(element.dataset.i18nArgs);
      } catch (ex) {
        throw new i18nError.UnableToParseArgs(`Error parsing args. Error: ${ex.message}`);
      }
    }
    if (element.dataset.i18nId) {
      element.textContent = localizeKey(element.dataset.i18nId, replacementArgs, opts);
    }
    if (element.dataset.i18nAttrs) {
      let requestedAttributes;
      try {
        requestedAttributes = JSON.parse(element.dataset.i18nAttrs);
      } catch (ex) {
        throw new i18nError.UnableToParseAttrs(`Error parsing attrs. Error: ${ex.message}`);
      }
      if (requestedAttributes && requestedAttributes.placeholder) {
        element.placeholder = localizeKey(requestedAttributes.placeholder, replacementArgs, opts);
      }
    }
  }
};

export { localizeKey, localizeKeys, localizeNode };
